import swal from 'sweetalert2'

/* eslint-disable */
export const messageBox = {

  displayStandard(title, message, type) {
    swal.fire({
      title: title,
      html: message,
      icon: type,
      timer: 6000,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn oci-close-button',
      },
      buttonsStyling: false,
    })
  },

  displayStandardWarning(title, message, type) {
    swal.fire({
      title: title,
      html: message,
      icon: type,
      timer: 6000,
      confirmButtonText: 'Close X',
      customClass: {
        confirmButton: 'btn oci-close-button',
      },
      buttonsStyling: false,
    })
  },

  displayErrorFromData(data) {
    if (data === undefined) return;
    if (data['error'] === undefined) return;

    const error = data['error'][1];

    let title = '';
    let message = '';

    const result = Array.isArray(error);
    if(result) {
        title = error[0];
        message = error[1];
    } else {
        title = 'Error';
        message = data['error'][0];
    }

    // const iterator = data['error'].values();
    // const title = iterator.next().value;
    // const message = iterator.next().value;
    this.displayStandard(title, message, 'warning');
  },

  displayTitleErrorFromData(data, message) {
    if (data === undefined) return;
    if (data['error'] === undefined) return;

    const error = data['error'][1];
    const title = error[0];
    // const iterator = data['error'].values();
    // const title = iterator.next().value;
    // const message = iterator.next().value;
    this.displayStandardWarning(title, message, 'warning');
  },  

}
/* eslint-enable */
